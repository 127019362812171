<template>
  <div>
    <br />
    <div class="row justify-content-center align-items-stratch">
      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionAImage"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionATitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionBImage"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionBTitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionCImage"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionCTitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row justify-content-center align-items-stratch">
      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionAImageAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionATitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionBImageAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionBTitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionCImageAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionCTitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          @click="
            $router.push({
              name: 'projects-edit',
              params: {
                slug: project.slug,
              },
            })
          "
          class="btn btn-warning"
        >
          Modifier
        </button>
      </div>
      <div class="col-auto">
        <button @click="remove(project.slug)" class="btn btn-danger">
          Supprimer
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("project/find", this.$route.params.slug);
  },
  computed: {
    ...mapGetters("project", { project: "getProject" }),
  },
  methods: {
    remove(slug) {
      if (confirm("") == true) {
        this.$store.dispatch("project/delete", slug);
      }
    },
  },
};
</script>
